import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import { CountryCode } from "../../../Constants";

const AuthorizedUserForm = ({
  userKey,
  state,
  dispatch,
  handleInputChange,
  validationErrors,
  validateRepresentativeEmails,
  validateRepresentativePhones,
  roleOptions,
  submitErrors,
}) => {
  const user = state[userKey];
  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontSize: "16px", fontWeight: 700, mt: "40px" }}
        data-testid={`${userKey}-authUserForm-heading`}
      >
        {userKey === "primary" ? "Primary Authorized User 1" : "Primary Authorized User 2"}
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="firstname"
              label="First Name"
              fullWidth
              variant="outlined"
              value={user.firstname}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              inputProps={{ maxLength: 70 }}
              data-testid={`${userKey}AuthUserForm-firstname-input`}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="lastname"
              label="Last Name"
              fullWidth
              variant="outlined"
              value={user.lastname}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              inputProps={{ maxLength: 70 }}
              data-testid={`${userKey}AuthUserForm-lastname-input`}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              label="Role"
              value={user.role}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-role-input`}
            >
              {roleOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} data-testid={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid={`${userKey}AuthUserForm-role-msg`}
            >
              Enter the role of the authorized representative
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="officialEmail"
              label="Official email address"
              fullWidth
              variant="outlined"
              value={user.officialEmail}
              onChange={(e) => {
                dispatch({ type: "updateNestedField", parent: userKey, event: e.target });
                validateRepresentativeEmails(userKey, e.target.value);
                handleInputChange(`${userKey}OfficialEmail`, e.target.value);
                submitErrors[`${userKey}OfficialEmailExists`] = "";
              }}
              error={
                !!validationErrors[`${userKey}OfficialEmail`] ||
                !!validationErrors.repEmailDupe ||
                !!submitErrors[`${userKey}OfficialEmailExists`]
              }
              helperText={
                validationErrors[`${userKey}OfficialEmail`] ||
                validationErrors.repEmailDupe ||
                submitErrors[`${userKey}OfficialEmailExists`]
              }
              data-testid={`${userKey}AuthUserForm-officialEmail-input`}
            />
            {!submitErrors[`${userKey}OfficialEmailExists`] &&
              !validationErrors[`${userKey}OfficialEmail`] &&
              !validationErrors.repEmailDupe && (
                <Typography
                  variant="subtitle2"
                  fontSize="12px"
                  color="#49454F"
                  data-testid={`${userKey}AuthUserForm-officialEmail-msg`}
                >
                  Enter the official email ID of the authorized personnel. This email will be used as primary source for
                  communication.
                </Typography>
              )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <Box display="flex">
              <TextField
                select
                label="Country Code"
                value={user.countryCodeRepresentativePhoneNumber} // Use the appropriate field from user
                onChange={(e) => {
                  dispatch({
                    type: "updateNestedField",
                    parent: userKey,
                    event: {
                      name: "countryCodeRepresentativePhoneNumber", // Specify the name for country code
                      value: e.target.value,
                    },
                  });
                }}
                variant="outlined"
                sx={{ minWidth: "100px", mr: 1 }}
                data-testid={`${userKey}AuthUserForm-countryCode-input`}
              >
                <MenuItem value={CountryCode.IN} data-testid={`${userKey}AuthUserForm-countryCode-options`}>
                  {CountryCode.IN}
                </MenuItem>
                <MenuItem value={CountryCode.GB} data-testid={`${userKey}AuthUserForm-countryCode-options`}>
                  {CountryCode.GB}
                </MenuItem>
                <MenuItem value={CountryCode.DE} data-testid={`${userKey}AuthUserForm-countryCode-options`}>
                  {CountryCode.DE}
                </MenuItem>
                <MenuItem value={CountryCode.AU} data-testid={`${userKey}AuthUserForm-countryCode-options`}>
                  {CountryCode.AU}
                </MenuItem>
              </TextField>

              <TextField
                name="representativePhoneNumber"
                label="Phone number"
                fullWidth
                variant="outlined"
                value={user.representativePhoneNumber}
                onChange={(e) => {
                  dispatch({
                    type: "updateNestedField",
                    parent: userKey,
                    event: e.target,
                  });
                  validateRepresentativePhones(userKey, e.target.value);
                  handleInputChange(`${userKey}PhoneNumber`, e.target.value);
                  submitErrors[`${userKey}RepresentativePhoneNumberExists`] = "";
                }}
                error={
                  !!validationErrors[`${userKey}PhoneNumber`] ||
                  !!validationErrors.repPhoneDupe ||
                  !!submitErrors[`${userKey}RepresentativePhoneNumberExists`]
                }
                helperText={
                  validationErrors[`${userKey}PhoneNumber`] ||
                  validationErrors.repPhoneDupe ||
                  submitErrors[`${userKey}RepresentativePhoneNumberExists`]
                }
                data-testid={`${userKey}AuthUserForm-repPhoneNumber-input`}
              />
            </Box>
            {!submitErrors[`${userKey}RepresentativePhoneNumberExists`] &&
              !validationErrors[`${userKey}PhoneNumber`] &&
              !validationErrors.repPhoneDupe && (
                <Typography
                  variant="subtitle2"
                  fontSize="12px"
                  color="#49454F"
                  data-testid={`${userKey}AuthUserForm-repPhoneNumber-msg`}
                >
                  Enter the phone number and country code of the authorized representative
                </Typography>
              )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="comment"
              label="Comments/notes"
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              value={user.comment}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-comment-input`}
            />
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid={`${userKey}AuthUserForm-comment-msg`}
            >
              Enter some comments or notes about the authorized personnel
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthorizedUserForm;
