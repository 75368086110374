import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { CountryCode } from "../../../Constants";

const BusinessDetailsForm = ({ state, dispatch, handleInputChange, validationErrors, submitErrors }) => {
  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontSize: "16px", fontWeight: 700, mt: "40px" }}
        data-testid="bizDetailsForm-panel-heading"
      >
        Business Details
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="businessName"
              label="Legal entity name"
              fullWidth
              variant="outlined"
              value={state.businessName}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange("businessName", e.target.value);
              }}
              error={!!validationErrors.businessName}
              helperText={validationErrors.businessName}
              data-testid="bizDetailsForm-businessName-input"
            />
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid="bizDetailsForm-businessName-msg"
            >
              Enter the company's official name that appears on government and legal forms
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="teamName"
              label="Business name on frontier"
              fullWidth
              variant="outlined"
              value={state.teamName}
              onChange={(e) => dispatch({ type: "updateField", event: e.target })}
              data-testid="bizDetailsForm-teamName-input"
            />
            <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-teamName-msg">
              Enter company's name to be represented on Frontier
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="businessEmailAddress"
              label="Business email address"
              fullWidth
              variant="outlined"
              value={state.businessEmailAddress}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange("businessEmailAddress", e.target.value);
                // Clear submit error when user starts typing
                submitErrors.businessEmailExists = "";
              }}
              error={!!validationErrors.businessEmailAddress || !!submitErrors.businessEmailExists}
              helperText={
                validationErrors.businessEmailAddress
                  ? validationErrors.businessEmailAddress
                  : submitErrors.businessEmailExists
              }
              data-testid="bizDetailsForm-bizEmail-input"
            />
            {!validationErrors.businessEmailAddress && !submitErrors.businessEmailExists && (
              <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-bizEmail-msg">
                Enter company's main email address (group or shared). Do not enter personal emails.
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <Box display="flex">
              <TextField
                select
                label="Country Code"
                value={state.countryCodeBusinessPhoneNumber}
                onChange={(e) => {
                  dispatch({
                    type: "updateField",
                    event: {
                      name: "countryCodeBusinessPhoneNumber", // Specify the name for country code
                      value: e.target.value,
                    },
                  });
                }}
                variant="outlined"
                sx={{ minWidth: "100px", mr: 1 }}
                data-testid={`bizDetailsForm-countryCode-input`}
              >
                <MenuItem value={CountryCode.IN} data-testid={`bizDetailsForm-countryCode-options`}>
                  {CountryCode.IN}
                </MenuItem>
                <MenuItem value={CountryCode.GB} data-testid={`bizDetailsForm-countryCode-options`}>
                  {CountryCode.GB}
                </MenuItem>
                <MenuItem value={CountryCode.DE} data-testid={`bizDetailsForm-countryCode-options`}>
                  {CountryCode.DE}
                </MenuItem>
                <MenuItem value={CountryCode.AU} data-testid={`bizDetailsForm-countryCode-options`}>
                  {CountryCode.AU}
                </MenuItem>
              </TextField>
              <TextField
                name="businessPhoneNumber"
                label="Business phone number"
                fullWidth
                variant="outlined"
                value={state.businessPhoneNumber}
                onChange={(e) => {
                  dispatch({ type: "updateField", event: e.target });
                  handleInputChange("businessPhoneNumber", e.target.value);
                }}
                error={!!validationErrors.businessPhoneNumber}
                helperText={validationErrors.businessPhoneNumber}
                data-testid="bizDetailsForm-bizPhone-input"
              />
            </Box>

            {/* Helper Text */}
            {!validationErrors.businessPhoneNumber && (
              <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-bizPhone-msg">
                Enter the company&rsquo;s main phone number and country code
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessDetailsForm;
