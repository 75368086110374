import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const SubscriptionPlanForm = ({ state, dispatch, plans, validationErrors, handleInputChange, submitErrors }) => {
  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontSize: "16px", fontWeight: 700, mt: "40px" }}
        data-testid="subsPlanForm-panel-heading"
      >
        Subscription plan details
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Pricing plan</InputLabel>
            <Select
              name="pricingPlan"
              label="Pricing plan"
              value={state.pricingPlan}
              onChange={(e) => dispatch({ type: "updateField", event: e.target })}
              data-testid="subsPlanForm-pricingPlan-input"
            >
              {plans.map((plan) => (
                <MenuItem key={plan.planCode} value={plan.planCode}>
                  {plan.planName}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="subsPlanForm-pricingPlan-msg">
              Enter the payment plan client would like to opt for
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="billingStartDate"
              label="Billing start date"
              fullWidth
              type="date"
              variant="outlined"
              value={state.billingStartDate}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange(`billingStartDate`, e.target.value);
                submitErrors[`billingStartDate`] = "";
              }}
              error={!!validationErrors[`billingStartDate`]}
              helperText={validationErrors[`billingStartDate`]}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().split("T")[0],
                  max: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() + 1)
                    .toISOString()
                    .split("T")[0],
                },
              }}
              data-testid="subsPlanForm-billingStartDate-input"
            />
            {!validationErrors.billingStartDate && (
              <Typography
                variant="subtitle2"
                fontSize="12px"
                color="#49454F"
                data-testid="subsPlanForm-billingStartDate-msg"
              >
                Enter the date from which billing starts for this client.
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionPlanForm;
